import React from "react"
import styles from "../styles/scss/components/proboardThankYou.module.scss"

const ProboardThankYou = ({ showModal, setShowModal }) => {

    return (
        <>
            <div showModal={showModal} className={styles.container}>
                <div className={styles.header}>
                    Proboard sign up
                    <div
                        tabIndex={0}
                        role="button"
                        onKeyDown={(e) => {
                            if (e.key !== "Tab" && e.key !== "Shift") {
                                setShowModal(false)
                            }
                        }}
                        onClick={() => setShowModal(false)}
                        className={styles.closeButton}
                    >
                        +
                    </div>
                </div>

                <div className={styles.container_text}>
                    <p>
                        Thank you for your interest in becoming a member of WD-40 Company’s PRO Board. We will be in touch with you shortly to determine next steps. If you have any questions, please email us at {" "}
                        <strong>
                            <a
                                style={{
                                    color: "#1c3687",
                                    borderBottom: "2px solid #1c3686",
                                }}
                                href="mailto: proboard@wd40.com"
                            >
                                proboard@wd40.com
                            </a>
                        </strong>
                        .
                    </p>
                </div>
            </div>
        </>
    )
}

export default ProboardThankYou
