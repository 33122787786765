import React, { useState } from "react"
import styles from "../styles/scss/templates/proboardSignUp.module.scss"
import ProboardThankYou from "./proboardThankYou"
import { signUp } from "../services/email"

const ProboardSignUp = ({ showModal, setShowModal }) => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")
    const [employee, setEmployee] = useState("")

    let handleSubmit = async e => {
        e.preventDefault()
        try {
            const data = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: mobileNumber,
                employeeFocus: employee || "Automotive",
                lists: [37547981],
                formIdentifier: 'proboard'
            }
            signUp(data).then(result => {
                setFirstName("")
                setLastName("")
                setEmail("")
                setMobileNumber("")
                setEmployee("")
                setShowModal(false)
                setThankYouModal(true)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const [thankYouModal, setThankYouModal] = useState(false)

    return (
        <>
            {showModal && !thankYouModal ? (
                <div className={styles.container}>
                    <div className={styles.header}>
                        PRO Board Sign Up
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => {
                                if (e.key !== "Tab" && e.key !== "Shift") {
                                    setShowModal(prev => !prev)
                                }
                            }}
                            onClick={() => setShowModal(prev => !prev)}
                            className={styles.closeButton}
                        >
                            +
                        </div>
                    </div>

                    <div className={styles.container_text}>
                        <p>
                            WD-40 Company’s PRO Board is looking for new members
                            to provide input on new products and uses, and
                            feedback on how we can help you get the job done
                            right. Sign-up below and a representative will be in
                            touch to see if you meet the criteria for
                            membership.
                        </p>
                    </div>

                    <form onSubmit={handleSubmit} className={styles.proboardForm}>
                        <div className={styles.form_row}>
                            <label id="name" htmlFor="proboard-name">First Name:</label>
                            <input
                                id="proboard-name"

                                type="text"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.form_row}>
                            <label id="lastName" htmlFor="proboard-lastName">Last Name:</label>
                            <input
                                id="proboard-lastName"

                                type="text"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.form_row}>
                            <label id="email" htmlFor="proboard-email">Email:</label>
                            <input
                                id="proboard-email"
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className={styles.form_row}>
                            <label id="phoneNumber" htmlFor="proboard-phoneNumber">Phone:</label>
                            <input
                                id="proboard-phoneNumber"
                                type="tel"
                                value={mobileNumber}
                                onChange={e => setMobileNumber(e.target.value)}
                                minLength="10"
                                maxLength="13"
                                required
                            />
                        </div>
                        <div className={styles.form_row}>
                            <label id="employent" htmlFor="proboard-employent">Employment Focus:</label>
                            <select
                                id="proboard-employent"
                                name="employent"
                                type="text"
                                value={employee}
                                onChange={e => setEmployee(e.target.value)}
                                required
                            >
                                <option value="Automotive">Automotive</option>
                                <option value="CST">CST</option>
                                <option value="MRO">MRO</option>
                                <option value="Farming">Farming</option>
                                <option value="DIY">DIY</option>
                            </select>
                        </div>
                        <div className={styles.form_row}>
                            <input
                                className={styles.blueButton}
                                type="submit"
                                value="Sign Up"
                            />
                        </div>
                    </form>
                </div>
            ) : null}

            {thankYouModal ? (
                <ProboardThankYou setShowModal={setThankYouModal} />
            ) : null}
        </>
    )
}

export default ProboardSignUp
