import React, { useState } from "react"
import Imgix from "react-imgix"
import NewLayout from "../layout/newLayout"
import SEO from "../components/seo"
import Title from "../components/title"
import styles from "../styles/scss/templates/proboard.module.scss"
import WhyJoinImageOne from "../../static/img/proboard/whyJoinImage1.png"
import WhyJoinImageTwo from "../../static/img/proboard/whyJoinImage2.png"
import ProboardImages1 from "../../static/img/proboard/proboard1.png"
import ProboardImages2 from "../../static/img/proboard/proboard2.png"
import ProboardImages3 from "../../static/img/proboard/proboard3.png"
import YouTube from "react-youtube"
import ProboardSignUp from "../components/proboardSignUp"
import CtaBanner from "../components/ctaBanner"
import Button from "../components/button"

const ProboardPage = ({ ...props }) => {
    const pageData = props.pageContext.page

    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(true)
    }

    const data = {
        hero: {
            heroTitle: "Welcome to the WD-40 Pro board",
            heroCtaText: "Join Today",
        },
        whatIsSection: {
            title: "WHAT IS THE WD-40® PRO BOARD?",
            body:
                "WD-40 Company’s PRO Board is a voluntary group of trade professionals and DIYers who serve as an informal advisory board and provide input on new products, product improvements and product uses. Founded in 2014, this nationwide group of automotive, construction, farming, maintenance, repair and operations (MRO) professionals and DIYers support ongoing internal research needs and foster a connection between the brand and end users.",
            quote:
                "WD-40 COMPANY IS INTERESTED IN MY FEEDBACK AND VALUES INPUT ON PRODUCT DEVELOPMENT AND PRODUCT TESTING.",
            quoteAuthor: "DAN BAKER",
            quoteMemberSince: "PRO BOARD MEMBER SINCE 2012",
            videoId: "RP6hoDTO2QM",
        },
        whyJoinSection: {
            title: "Why Join",
            body1:
                "PRO Board members are often unsung heroes, and through the PRO Board men and women can be recognized for their knowledge and skills by providing their valuable opinions and unfiltered product feedback and opinions. At WD-40 Company, one of our values is to make it better than it is today, while creating positive lasting memories — being part of the PRO Board is a great way to make these things happen.",
            listItems: [
                "All activities are on a volunteer basis",
                `"Advisor" to develop the next generation of products/services`,
                `"First" to test prototype and provide unbiased feedback`,
                "Join the prestigious PRO Board",
                "Access to WD-40 Company's PRO Board Newsletter, branded swag, etc.",
            ],
            body2:
                "If you have additional questions or comments about PRO Board, please email us at ",
            contactEmail: "proboard@wd40.com",
            members: [
                {
                    name: "DAN B. (MRO), PA",
                    title: "PRO BOARD AMBASSADOR",
                    joined: "JOINED 2015",
                    image: WhyJoinImageOne,
                    imageAlt: "Why Join? Image 1",
                },
                {
                    name: "RICARDO M. (CST), CA",
                    title: "PRO BOARD AMBASSADOR",
                    joined: "JOINED 2017",
                    image: WhyJoinImageTwo,
                    imageAlt: "Why Join? Image 2",
                },
            ],
            ctaText: "Join Today",
        },
        whoCanApplySection: {
            title: "WHO CAN APPLY",
            body:
                "WD-40 Company's PRO Board has three levels of membership — members, ambassadors and masters — which offer varying levels of participation and commitment.",
            levels: [
                {
                    levelImage: ProboardImages1,
                    levelImageAlt: "Proboard Member",
                    levelTitle: "MEMBERS",
                    levelItems: [
                        "Be active in the automotive, constrution, farming or MRO industry, or be a DIY enthusiast.",
                        "Be knowledgeable about industry best practices in their respective fields.",
                        "          Be willing to provide objective, unbiased feedback in a research capacity regarding WD-40 Company's existing and new products and delivery systems.",
                        " Be willing to sign a non-disclosure agreement and a photo release form.",
                    ],
                },
                {
                    levelImage: ProboardImages2,
                    levelImageAlt: "Proboard Ambassador",
                    levelTitle: "AMBASSADORS",
                    levelItems: [
                        "Meet all Member requirements, plus:",
                        "Demonstrate a vast knowledge of their respective trade industry, including automotive, construction(CST), maintenance, repair and operations(MRO) or farming.",
                        "Be able to claim at least three years of experience in their respective trade industry.",
                        "Have a basic knowledge of WD-40 Company and its history.",
                    ],
                },
                {
                    levelImage: ProboardImages3,
                    levelImageAlt: "Proboard Master",
                    levelTitle: "MASTERS",
                    levelItems: [
                        "Meet all Ambassador requirements, plus:",
                        "Have in-depth knowledge of WD-40 Brand products.",
                        "Adhere to WD-40 Company's values.",
                        "Be willing to participate in yearly business meetings, Tribology presentations or panel discussions.",
                        "Serve as an industry leader in their respective field with thorough knowledge of industry best practices.",
                    ],
                },
            ],
        },
        ctaBanner: {
            text: "AS A PRO BOARD MEMBER, YOU’LL BE IN GOOD COMPANY.",
            ctaText: "Join Today",
        },
    }

    return (
        <NewLayout>
            <SEO page={pageData} />
            <section className={styles.heroContainer}>
                {
                    showModal &&
                    <ProboardSignUp
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />
                }

                <div className={styles.heroWrapper}>
                    <Title
                        className={styles.heroTitle}
                        value={data.hero.heroTitle}
                    />
                    <Button
                        redButton
                        text={data.hero.heroCtaText}
                        className={styles.redButton}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e => {
                            e.preventDefault()
                            openModal()
                        }}
                    />
                </div>
            </section>

            <section className={styles.whiteSection}>
                <div className={styles.whiteSection__contentWrapper}>
                    <div
                        className={styles.whiteSection__contentWrapper___title}
                    >
                        {data.whatIsSection.title}
                    </div>
                    <div className={styles.videoWrapper}>
                        <div className={styles.videoContent}>
                            <div
                                className={
                                    styles.whiteSection__contentWrapper__bodyText
                                }
                            >
                                {data.whatIsSection.body}
                            </div>
                            <div className={styles.iframeContainer}>
                                <YouTube
                                    videoId={data.whatIsSection.videoId}
                                    opts={{
                                        playerVars: {
                                            rel: 0,
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <div className={styles.quote_section}>
                            <p>
                                {data.whatIsSection.quote}
                                <br />
                                <br />
                                {data.whatIsSection.quoteAuthor}
                                <br />
                                {data.whatIsSection.quoteMemberSince}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.whyJoinWrapper}>
                <div className={styles.whyJoinContent}>
                    <div className={styles.whyJoinColumn1}>
                        {data.whyJoinSection.members &&
                            data.whyJoinSection.members.map((member, key) => {
                                return (
                                    <div key={key}>
                                        <Imgix
                                            sizes="100vw"
                                            className={
                                                styles.bottomSectionImage
                                            }
                                            src={member.image}
                                            htmlAttributes={{alt: member.imageAlt}}
                                        />
                                        <div
                                            className={styles.whyJoinImageText}
                                            style={{ paddingBottom: 20 }}
                                        >
                                            <strong>{member.name}</strong>
                                            <br />
                                            {member.title}
                                            <br />
                                            {member.joined}
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                    <div className={styles.whyJoinColumn2}>
                        <div className={styles.whyJoinColumn2Content}>
                            <div className={styles.whyJoinTitle}>
                                <span>{data.whyJoinSection.title}</span>
                            </div>
                            <div className={styles.whyJoinText}>
                                <p>{data.whyJoinSection.body1}</p>
                                <ul>
                                    {data.whyJoinSection.listItems &&
                                        data.whyJoinSection.listItems.map(
                                            (item, key) => {
                                                return <li key={key}> {item} </li>
                                            }
                                        )}
                                </ul>
                                <p>
                                    {data.whyJoinSection.body2}
                                    <strong>
                                        <a
                                            href={`mailto: ${data.whyJoinSection.contactEmail}`}
                                        >
                                            {data.whyJoinSection.contactEmail}
                                        </a>
                                    </strong>
                                    .
                                </p>
                            </div>
                        </div>
                        <div className={styles.whyJoinButton}>
                            <button
                                onClick={e => {
                                    e.preventDefault()
                                    openModal()
                                }}
                                className={styles.yellowButton}
                            >
                                {data.whyJoinSection.ctaText}
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.secondWhiteSection}>
                <div className={styles.secondWhiteSection__contentWrapper}>
                    <div
                        className={
                            styles.secondWhiteSection__contentWrapper___title
                        }
                    >
                        {data.whoCanApplySection.title}
                    </div>
                    <div
                        className={
                            styles.secondWhiteSection__contentWrapper__bodyText
                        }
                    >
                        {data.whoCanApplySection.body}
                    </div>
                    <div className={styles.row}>
                        {data.whoCanApplySection.levels &&
                            data.whoCanApplySection.levels.map((level, key) => {
                                return (
                                    <div key={key} className={styles.column}>
                                        <Imgix
                                            sizes="100vw"
                                            className={styles.proboardImagesAll}
                                            src={level.levelImage}
                                            htmlAttributes={{alt: level.levelImageAlt}}
                                        />
                                        <div className={styles.proboardTitles}>
                                            {level.levelTitle}
                                        </div>
                                        <div className={styles.proboardText}>
                                            <ul>
                                                {level.levelItems.map((item, key) => {
                                                    return <li key={key}>{item}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </section>

            <CtaBanner
                text={data.ctaBanner.text}
                buttonText={data.ctaBanner.ctaText}
                onClick={e => {
                    e.preventDefault()
                    openModal()
                }}
            />
        </NewLayout>
    )
}

export default ProboardPage
